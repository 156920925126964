import React, { useEffect, useState } from "react";
import { MdConstruction } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import img from "../static/contactimg.jpg";

interface JobberEmbedProps {
  url: string;
}

const JobberEmbed: React.FC<JobberEmbedProps> = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      const loadJobberScript = () => {
        const scriptId = "jobber-script";
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
          return;
        }
        const script = document.createElement("script");
        script.src =
          "https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js";
        script.id = scriptId;
        script.type = "text/javascript";
        script.setAttribute(
          "clienthub_id",
          "9aa4aa6e-60a7-4b47-8902-73359a70ecca"
        );
        script.setAttribute(
          "form_url",
          "https://clienthub.getjobber.com/client_hubs/9aa4aa6e-60a7-4b47-8902-73359a70ecca/public/work_request/embedded_work_request_form"
        );
        document.body.appendChild(script);

        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href =
          "https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css";
        document.head.appendChild(link);
      };

      loadJobberScript();
    }
  }, [isModalOpen]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div id="contact" className=" bg-white/5  py-10 mt-20">
      <div className="mx-auto max-w-screen-7xl flex flex-col items-center px-6 rounded-md">
        <div className="flex flex-col md:flex-row justify-between md:gap-24 items-center">
          <div className="flex flex-col max-w-xl">
            <h2 className="text-4xl mb-10 md:mb-0 text-burntred font-bold lg:text-5xl text-center md:text-left">
              Let's Chat
            </h2>
            <p className="mt-3 mb-8 text-white text-center md:text-left">
              Ready to partner with us and offer stunning outdoor structures to
              your clients? Schedule a free consultation with our expert team.
              Use our convenient booking calendar to find a time that works for
              you, and let’s discuss how you can become a dealer for our
              high-quality pergolas, pool pavilions, and outdoor kitchens.
            </p>
            <div className="pt-10 md:pt-16">
              <button
                onClick={openModal}
                className="bg-white text-burntred font-semibold py-4 px-6 rounded-lg hover:bg-white/80 transition-all duration-300 flex items-center justify-center mx-auto md:mx-0 md:justify-start gap-6"
              >
                Become a Dealer <MdConstruction color="#C15833" />
              </button>
            </div>
          </div>
          <div className="mt-8 md:mt-0">
            <img
              className="max-w-xs rounded-sm md:block hidden"
              src={img}
              alt="Contact"
            />
          </div>
        </div>

        {isModalOpen && (
          <div className="z-50 fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 p-6">
            <div
              className="bg-white rounded-lg shadow-lg max-w-3xl w-full p-6 relative overflow-y-auto"
              style={{ maxHeight: "90vh" }}
            >
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
              >
                <IoMdClose className="fill-navy w-8 h-8" />
              </button>
              <div
                id="jobber-inline-widget"
                className="w-full sm:h-[40em] h-[30em]"
                style={{ minHeight: "400px" }}
              >
                <div
                  className=""
                  id="9aa4aa6e-60a7-4b47-8902-73359a70ecca"
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobberEmbed;
