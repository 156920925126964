import React from "react";
import { FaUtensils } from "react-icons/fa";
import { MdOutlinePool, MdOutlineDeck } from "react-icons/md";
import { GiWoodBeam } from "react-icons/gi";

const Process = () => {
  const processSteps = [
    {
      icon: <MdOutlinePool className="text-gray-300 w-6 h-6" />,
      title: "Pool Pavillions",
      description:
        "Elegant and durable pool pavilions to enhance your outdoor experience.",
    },
    {
      icon: <FaUtensils className="text-gray-300 w-6 h-6" />,
      title: "Outdoor Kitchens",
      description:
        "Fully-equipped outdoor kitchens perfect for alfresco dining.",
    },
    {
      icon: <MdOutlineDeck className="text-gray-300 w-6 h-6" />,
      title: "Pergolas",
      description: "Beautifully crafted pergolas providing shade and style.",
    },
    {
      icon: <GiWoodBeam className="text-gray-300 w-6 h-6" />,
      title: "Porches",
      description:
        "Custom-designed porches to extend your living space outdoors.",
    },
  ];

  return (
    <div className="text-white py-14">
      <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
        <h2 className="text-center py-8 text-white text-2xl font-semibold">
          What We Build
        </h2>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 items-start gap-12">
          {processSteps.map((step, index) => (
            <div className="text-center" key={index}>
              <div className="flex justify-center items-center size-12 bg-white/10 border border-gray-200 rounded-full mx-auto ">
                {step.icon}
              </div>
              <div className="mt-3">
                <h3 className="text-lg font-semibold text-burntred">
                  {step.title}
                </h3>
                <p className="mt-1 text-white px-8 sm:px-0">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Process;
