import React from "react";

const details = () => {
  return (
    <div>
      <div className="bg-white/10 py-6 sm:py-8 lg:py-24 my-16">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="mx-auto flex max-w-xl flex-col items-center text-center">
            <p className="mb-4 font-semibold text-burntred md:mb-6 md:text-lg xl:text-xl">
              What Are You Waiting For
            </p>

            <h1 className="mb-8 max-w-sm text-3xl font-bold text-white sm:text-4xl md:mb-12 md:text-5xl">
              Become A Dealer Today
            </h1>

            <div className="flex md:w-full flex-col gap-2.5 sm:flex-row sm:justify-center">
              <a
                href="#"
                className="block w-full rounded bg-burntred px-12 py-3 text-sm font-medium text-white shadow hover:burntred focus:outline-none focus:ring  sm:w-auto"
              >
                Become A Dealer
              </a>

              <a
                href="#"
                className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-burntred shadow hover:text-burntred/80 focus:outline-none focus:ring  sm:w-auto"
              >
                View Projects
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default details;
