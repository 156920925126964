// src/data/dealersData.ts

import bk from "../static/galleryphotos/slate-cypress/2.jpg";

interface Dealer {
  id: string;
  name: string;
  description: string;
  url: string;
  image: string;
  experience: string;
  projectnum: string;
  number: string;
  email: string;
  location: string;
  // Add other dealer details here
}

const dealers: Dealer[] = [
  {
    id: "bidking",
    name: "BidKing",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi posuere lorem sit amet euismod viverra. Etiam tempor dignissim magna, et tristique magna placerat id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean ante felis, sagittis in diam eget, pulvinar sodales nisi. Nunc id nisl rhoncus, sodales dolor Lorem ipsum dolor sit amet, consectetur.",
    url: "https://www.bidking.com",
    image: bk,
    experience: "10",
    projectnum: "50",
    number: "+123456789",
    email: "contact@bidking.com",
    location: "3806 E Providence Ct, Wilmington, NC",
  },
  {
    id: "backyard-specialist",
    name: "Backyard Specialist",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi posuere lorem sit amet euismod viverra. Etiam tempor dignissim magna, et tristique magna placerat id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean ante felis, sagittis in diam eget, pulvinar sodales nisi. Nunc id nisl rhoncus, sodales dolor Lorem ipsum dolor sit amet, consectetur.",
    url: "https://www.backyardspecialist.com",
    image: bk,
    experience: "8",
    projectnum: "120",
    number: "+1122334455",
    email: "info@backyardspecialist.com",
    location: "789 Garden Lane, Springfield, IL",
  },
  {
    id: "bloomin-crazy",
    name: "Bloomin Crazy",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi posuere lorem sit amet euismod viverra. Etiam tempor dignissim magna, et tristique magna placerat id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean ante felis, sagittis in diam eget, pulvinar sodales nisi. Nunc id nisl rhoncus, sodales dolor Lorem ipsum dolor sit amet, consectetur.",
    url: "https://www.bloomin-crazy.com",
    image: bk,
    experience: "5",
    projectnum: "75",
    number: "+2233445566",
    email: "hello@bloomin-crazy.com",
    location: "123 Bloom Street, Flower City, CA",
  },
  {
    id: "buenavista",
    name: "Buenavista",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi posuere lorem sit amet euismod viverra. Etiam tempor dignissim magna, et tristique magna placerat id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean ante felis, sagittis in diam eget, pulvinar sodales nisi. Nunc id nisl rhoncus, sodales dolor Lorem ipsum dolor sit amet, consectetur.",
    url: "https://www.buenavista.com",
    image: bk,
    experience: "12",
    projectnum: "200",
    number: "+3344556677",
    email: "contact@buenavista.com",
    location: "456 Vista Road, Buena Park, TX",
  },
  {
    id: "hedgepeth",
    name: "Hedgepeth",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi posuere lorem sit amet euismod viverra. Etiam tempor dignissim magna, et tristique magna placerat id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean ante felis, sagittis in diam eget, pulvinar sodales nisi. Nunc id nisl rhoncus, sodales dolor Lorem ipsum dolor sit amet, consectetur.",
    url: "https://www.hedgepeth.com",
    image: bk,
    experience: "20",
    projectnum: "500",
    number: "+4455667788",
    email: "support@hedgepeth.com",
    location: "321 Maintenance Blvd, Homeville, FL",
  },
];

export default dealers;
