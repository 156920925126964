// src/components/DealerDetail.tsx

import React from "react";
import { useParams } from "react-router-dom";
import dealers from "../data/dealersData.tsx";
import Header from "./header.tsx";
import { IoMdCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";

const DealerDetail: React.FC = () => {
  const { dealerId } = useParams<{ dealerId: string }>();
  const dealer = dealers.find((d) => d.id === dealerId);

  if (!dealer) {
    return <div>Dealer not found</div>;
  }

  return (
    <div className="bg-steel">
      <div className="pb-20">
        <Header />
      </div>
      <div className="h-full text-white">
        <div className="grid grid-cols-1 gap-8 p-4 lg:p-20 max-w-[100rem] mx-auto lg:grid-cols-2 mb-6">
          <div className="flex flex-col justify-between max-w-lg mx-auto">
            <div>
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold mb-4">
                {dealer.name}
              </h1>
              <p className="text-base md:text-lg mb-4">{dealer.description}</p>
            </div>
            <div className="flex justify-start">
              <button className="lg:w-auto lg:max-w-xs w-full rounded bg-burntred px-3 sm:px-8 md:px-12 py-3 text-xs lg:mt-10 sm:text-sm font-medium text-white shadow hover:bg-burntred focus:outline-none">
                <a href={dealer.url} target="_blank" rel="noopener noreferrer">
                  View Site
                </a>
              </button>
            </div>
          </div>

          <div className="flex flex-col justify-between max-w-lg mx-auto  lg:mt-0">
            <img
              src={dealer.image}
              alt={dealer.name}
              className="w-full h-52 mt-10 md:mt-0 md:h-72 object-cover border p-2 border-white/20 rounded-sm"
            />
            <div className="flex flex-row gap-4 md:gap-20 mx-auto p-4 md:p-8 md:pb-0">
              <div className="flex flex-col gap-2 items-center text-center">
                <p className="text-4xl md:text-7xl font-semibold">
                  {dealer.experience}+
                </p>
                <p className="text-sm md:text-lg">Years Of Experience</p>
              </div>

              <div className="flex flex-col gap-2 items-center text-center">
                <p className="text-4xl md:text-7xl font-semibold">
                  {dealer.projectnum}+
                </p>
                <p className="text-sm md:text-lg">Projects Delivered</p>
              </div>
            </div>
          </div>
        </div>

        {/* Contact Info */}
        <div className="flex">
          <h1 className="text-3xl md:text-5xl text-white font-semibold mx-auto pt-10">
            Contact Information
          </h1>
        </div>
        <div className="bg-white/10 py-6 sm:py-8 lg:py-24 my-8 md:my-16">
          <div className="flex flex-col lg:flex-row max-w-[100rem] mx-auto justify-center gap-8 lg:gap-36 p-4 ">
            <div className="flex flex-col items-center text-center mb-10 md:mb-0">
              <div className="mb-4 text-7xl text-white">
                <IoIosMail />
              </div>

              <h1 className="text-lg md:text-sm mb-4 text-white max-w-xs">
                We aim to reply to all emails within 24-48 hours; mark your
                email as urgent if needed
              </h1>

              <div className="flex flex-col sm:flex-row sm:justify-center">
                <h1 className="text-xl md:text-3xl font-bold text-white">
                  Info@bidking.com
                </h1>
              </div>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="mb-4 text-7xl  text-white">
                <IoMdCall />
              </div>

              <h1 className="text-lg md:text-sm mb-4 text-white max-w-xs">
                Our office hours are Monday to Friday, 9:00 AM - 5:00 PM (EST).
              </h1>

              <div className="flex flex-col sm:flex-row sm:justify-center">
                <h1 className="text-xl md:text-3xl font-bold text-white">
                  +1 (800) 123-4567
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row max-w-[100rem] mx-auto p-4 md:p-20">
          <div className="w-full lg:w-1/3 p-4">
            <h2 className="text-3xl md:text-5xl font-semibold mb-6">
              Dealer Location
            </h2>
            <p className="text-base md:text-lg text-white">
              We proudly serve a 50-mile radius from this location. If you’re
              within this area, we’re ready to bring our construction expertise
              right to your project site. Use the map below to find our location
              and get in touch with us to discuss how we can assist with your
              needs. We’re here to provide top-notch service and support for
              your construction projects.
            </p>
          </div>

          <div className="w-full lg:w-2/3 p-4">
            <div className="w-full h-[20em] md:h-[30em]">
              <iframe
                src={`https://www.google.com/maps?q=${encodeURIComponent(
                  dealer.location
                )}&output=embed`}
                className="w-full h-full p-2 border rounded-sm border-white/20"
                allowFullScreen
                loading="lazy"
                title="Google Map"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerDetail;
