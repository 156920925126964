import React from "react";
import { useParams } from "react-router-dom";
import Header from "./header.tsx";
import Footer from "./footer.tsx";
import s1 from "../static/galleryphotos/slate-cypress/1.jpg";
import s2 from "../static/galleryphotos/slate-cypress/2.jpg";
import s3 from "../static/galleryphotos/slate-cypress/3.jpg";
import s4 from "../static/galleryphotos/slate-cypress/4.jpg";
import s5 from "../static/galleryphotos/slate-cypress/5.jpg";
import s6 from "../static/galleryphotos/slate-cypress/6.jpg";
import s7 from "../static/galleryphotos/slate-cypress/7.jpg";
import s8 from "../static/galleryphotos/slate-cypress/8.jpg";

import sr1 from "../static/galleryphotos/stoney-ridge/1.jpg";
import sr2 from "../static/galleryphotos/stoney-ridge/2.jpg";
import sr3 from "../static/galleryphotos/stoney-ridge/3.jpg";
import sr4 from "../static/galleryphotos/stoney-ridge/4.jpg";

import h1 from "../static/galleryphotos/hedgepeth/1.jpg";
import h2 from "../static/galleryphotos/hedgepeth/2.jpg";
import h3 from "../static/galleryphotos/hedgepeth/3.jpg";
import h4 from "../static/galleryphotos/hedgepeth/4.jpg";
import h5 from "../static/galleryphotos/hedgepeth/5.jpg";

import b1 from "../static/galleryphotos/bidking/1.jpg";
import b2 from "../static/galleryphotos/bidking/2.jpg";
import b3 from "../static/galleryphotos/bidking/3.jpg";
import b4 from "../static/galleryphotos/bidking/4.jpg";

const projectDetails = {
  "slate-and-cypress": {
    name: "Slate & Cypress",
    images: [
      {
        src: s1,
        description:
          "A modern living room with sleek furniture and warm lighting.",
      },
      {
        src: s2,
        description:
          "The kitchen features state-of-the-art appliances and a spacious island.",
      },
      {
        src: s3,
        description:
          "The dining area, perfect for family gatherings, has a minimalist design.",
      },
      {
        src: s4,
        description:
          "A cozy bedroom with large windows allowing natural light to fill the space.",
      },
      {
        src: s5,
        description:
          "The bathroom showcases a luxurious tub and elegant fixtures.",
      },
      {
        src: s6,
        description:
          "An outdoor patio with comfortable seating and a beautiful garden view.",
      },
      {
        src: s7,
        description: "A stylish home office setup, ideal for productivity.",
      },
      {
        src: s8,
        description:
          "The exterior of the house, highlighting its modern architecture.",
      },
    ],
  },
  "stoney-ridge": {
    name: "Stoney Ridge",
    images: [
      {
        src: sr1,
        description:
          "The spacious living room with a stone fireplace and rustic decor.",
      },
      {
        src: sr2,
        description:
          "A kitchen with wooden cabinets and a large central island.",
      },
      {
        src: sr3,
        description:
          "The master bedroom, featuring a vaulted ceiling and cozy atmosphere.",
      },
      {
        src: sr4,
        description:
          "The backyard, perfect for outdoor entertaining with a large deck.",
      },
    ],
  },
  hedgepeth: {
    name: "Hedgepeth",
    images: [
      {
        src: h1,
        description:
          "A charming living room with vintage furniture and decorative touches.",
      },
      {
        src: h2,
        description:
          "The dining room, exuding elegance with its classic design and chandelier.",
      },
      {
        src: h3,
        description:
          "The dining room, exuding elegance with its classic design and chandelier.",
      },
      {
        src: h4,
        description:
          "The dining room, exuding elegance with its classic design and chandelier.",
      },
      {
        src: h5,
        description:
          "The dining room, exuding elegance with its classic design and chandelier.",
      },
    ],
  },
  bidking: {
    name: "BidKing",
    images: [
      {
        src: b1,
        description:
          "A luxurious office space with modern amenities and stylish decor.",
      },
      {
        src: b2,
        description:
          "The conference room, designed for professionalism and comfort.",
      },
      {
        src: b3,
        description:
          "The conference room, designed for professionalism and comfort.",
      },
      {
        src: b4,
        description:
          "The conference room, designed for professionalism and comfort.",
      },
    ],
  },
};

const ProjectDetail: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  if (!projectId || !projectDetails[projectId]) {
    return <div>Project not found</div>;
  }

  const project = projectDetails[projectId];

  return (
    <div className="bg-steel">
      <Header />
      <div className="mx-auto w-screen p-6 bg-steel mb-20">
        <h1 className="text-4xl text-center text-white font-semibold mb-8 mt-28  ">
          {project.name}
        </h1>
        <div className="mx-auto grid grid-cols-1 max-w-6xl md:grid-cols-2 lg:grid-cols-3 gap-6">
          {project.images.map((image, index) => (
            <div
              key={index}
              className="flex flex-col items-center border p-2 border-white/20 rounded-sm"
            >
              <img
                className="object-cover object-center w-96 rounded-sm h-72"
                src={image.src}
                alt={`Image ${index + 1}`}
              />
              {/* <p className="mt-4 p-2 text-left text-white">
                {image.description}
              </p> */}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectDetail;
