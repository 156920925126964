import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../static/cbb.png";

const dealers = [
  { id: "bidking", name: "BidKing" },
  { id: "backyard-specialist", name: "Backyard Specialist" },
  { id: "bloomin-crazy", name: "Bloomin Crazy" },
  { id: "buenavista", name: "Buenavista" },
  { id: "hedgepeth", name: "Hedgepeth" },
];

const ConversionTrackingComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      gtag('event', 'conversion', {'send_to': 'AW-16657191665/NOm2CK7OxscZEPGl4oY-'});
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

const Header = () => {
  const [state, setState] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigation = [
    { title: "What We Do", path: "#wwd" },
    { title: "Testimonials", path: "#testimonials" },
    { title: "Our Dealers", path: "#clients" },
    { title: "Become A Dealer", path: "#contact" },
  ];

  const handleNavigation = (path) => {
    const homePath = "/";
    if (location.pathname !== homePath) {
      navigate(homePath);
      setTimeout(() => {
        document.querySelector(path).scrollIntoView({ behavior: "smooth" });
      }, 100); // Adjust the timeout if necessary
    } else {
      document.querySelector(path).scrollIntoView({ behavior: "smooth" });
    }
    setState(false); // Close the mobile menu
    setDropdownOpen(false); // Close the dropdown menu
  };

  const handleDealerNavigation = (dealerId) => {
    navigate(`/dealer/${dealerId}`);
    setState(false); // Close the mobile menu
    setDropdownOpen(false); // Close the dropdown menu
  };

  return (
    <div className="fixed z-10 w-screen">
      <nav className="bg-steel bg-opacity-100 backdrop-blur-md w-full md:border-0 md:static">
        <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8">
          <div className="flex items-center justify-between py-1 md:py-2 md:block">
            <a href="/">
              <img src={logo} width={70} height={70} alt="Float UI logo" />
            </a>
            <div className="md:hidden">
              <button
                className="text-white outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                onClick={() => setState(!state)}
              >
                {state ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 8h16M4 16h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              state ? "block" : "hidden"
            }`}
          >
            <ul className="justify-center items-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              {navigation.map((item, idx) => (
                <li
                  key={idx}
                  className="text-white hover:text-burntred transition-all duration-200"
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigation(item.path);
                    }}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
              {/* <li
                className="relative text-white hover:text-burntred transition-all duration-200"
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                Dealers
                {dropdownOpen && (
                  <ul
                    className="absolute left-0 mt-0 w-48 bg-steel text-white shadow-lg"
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    {dealers.map((dealer) => (
                      <li key={dealer.id}>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-white/5"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDealerNavigation(dealer.id);
                          }}
                        >
                          {dealer.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li> */}
            </ul>
          </div>
          <div className="hidden md:inline-block">
            <a
              href="#gallery"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("#gallery");
              }}
              className="py-3 px-4 text-white bg-burntred hover:bg-burntred/70 rounded-md shadow transition-all duration-300"
            >
              View Projects
            </a>
          </div>
        </div>
      </nav>
      <ConversionTrackingComponent />
    </div>
  );
};

export default Header;
