import React from "react";
import kit1 from "../static/kitchen.jpg";
import kit2 from "../static/pavillion.jpg";
import kit3 from "../static/pergula.jpg";

const Kits = () => {
  const kitsData = [
    {
      imgSrc: kit1,
      title: "Timberframe Pavillion",

      description:
        "Beautiful dark stained 18'x 24' Timberframe Pavillion located in Sunset Beach, NC",
    },
    {
      imgSrc: kit2,
      title: "Timberframe Pool Pavillion",

      description:
        "Elegant dark stained Pool Pavillion located in Calabash, NC",
    },
    {
      imgSrc: kit3,
      title: "Outdoor Kitchen Pavillion",

      description:
        "Quaint 14'x14' Timberframe Pavillion with outdoor kitchen in Southport, NC",
    },
  ];

  return (
    <div id="wwd" className="">
      <section className="flex flex-col justify-center px-8">
        <div className="py-6 sm:py-8 lg:py-20">
          <div className="mx-auto max-w-screen-2xl ">
            <p className="mb-2 font-semibold text-burntred md:mb-3 lg:text-lg">
              Built To Last
            </p>

            <h2 className="mb-4 text-3xl font-semibold text-white md:mb-6 lg:text-5xl">
              Our recent projects
            </h2>

            <p className="max-w-screen-md text-white text-xs md:text-lg">
              Explore our recent projects, where durability, elegance, and
              sustainability come together. We specialize in stunning pool
              pavilions, pergolas, and outdoor kitchens crafted from robust wood
              beams. Our designs transform outdoor spaces into luxurious
              retreats, blending quality craftsmanship with aesthetic appeal.
              Discover how our structures bring outdoor living dreams to life
              with unmatched functionality and style.
            </p>
          </div>
        </div>
        <div className="ml-0 md:ml-12 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-3 gap-8  md:px-12">
          {kitsData.map((kit, index) => (
            <a href="#" key={index} className="w-full">
              <article className="relative overflow-hidden rounded-sm shadow transition hover:shadow-xl hover:scale-105">
                <img
                  src={kit.imgSrc}
                  alt={kit.title}
                  className="absolute inset-0 h-full w-full object-cover"
                />
                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64">
                  <div className="p-4 sm:p-6">
                    <h3 className="mt-0.5 text-xl font-semibold text-white">
                      {kit.title}
                    </h3>
                    <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                      {kit.description}
                    </p>
                    <a
                      href="#gallery"
                      className="inline-block mt-4 px-6 py-2 bg-burntred text-white text-sm rounded transition hover:bg-burntred/80"
                    >
                      See More
                    </a>
                  </div>
                </div>
              </article>
            </a>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Kits;
