import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header.tsx";
import Hero from "./components/hero.tsx";
import Kits from "./components/kits.tsx";
import Process from "./components/process.tsx";
import TabbedGallery from "./components/tabbedgallery.tsx";
import Details from "./components/details.tsx";
import Testimonials from "./components/testimonials.tsx";
import ClientsTicker from "./components/clients.tsx";
import ProjectDetail from "./components/projectdetail.tsx";
import JobberEmbed from "./components/jobberEmbed.tsx";
import Footer from "./components/footer.tsx";
import DealerDetail from "./components/dealerdetail.tsx";
import { Analytics } from "@vercel/analytics/react";

const MainContent = () => (
  <>
    <Header />
    <Hero />
    <div className="bg-steel">
      <div className="max-w-[100rem] mx-auto">
        <Kits />
        <Process />
      </div>
      <Details />
      <div className="max-w-[100rem] mx-auto">
        <Testimonials />
        <ClientsTicker />
        <TabbedGallery />
        <JobberEmbed />
        <Footer />
      </div>
    </div>
    <Analytics />
  </>
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/project/:projectId" element={<ProjectDetail />} />
        <Route path="/dealer/:dealerId" element={<DealerDetail />} />
      </Routes>
    </Router>
  );
};

export default App;
