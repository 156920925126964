import React from "react";
import mobileImage from "../static/pavillion.jpg"; // Ensure you have an image for mobile

const Hero = () => {
  return (
    <div>
      <section className="relative bg-cover bg-center bg-no-repeat">
        <div className="absolute inset-0 w-full h-full hidden md:block">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/uzKZZchjMKw?si=g9VoRQlpCeez3jxY&amp;controls=0&autoplay=1&mute=1&loop=1&playlist=uzKZZchjMKw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="absolute inset-0 w-full h-full object-cover opacity-80 md:opacity-50"
          ></iframe>
        </div>

        <div className="absolute inset-0 w-full h-full md:hidden">
          <img
            src={mobileImage}
            alt="Quality Timber Structures"
            className="w-full h-full object-cover opacity-80 md:opacity-30"
          />
        </div>

        <div className="absolute inset-0 bg-black/50 sm:bg-transparent sm:from-black/60 sm:to-black/20 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

        <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
          <div className="max-w-5xl flex flex-col justify-center items-center mx-auto text-center ">
            <h1 className="text-4xl text-white font-extrabold sm:text-7xl">
              Quality Timber Structures
              <strong className="block font-extrabold text-burntred">
                {" "}
                For Timeless Outdoor Living{" "}
              </strong>
            </h1>

            <p className="mt-4 text-white max-w-2xl text-center text-xs sm:text-xl/relaxed">
              Expertly crafted pergolas, pool pavilions, and outdoor kitchens
              designed to elevate your outdoor space.
            </p>

            <div className="mt-8 flex w-full justify-center flex-wrap gap-4 text-center sm:text-left">
              <a
                href="#gallery"
                className="block w-2/3 rounded bg-burntred px-3 sm:px-12 py-3 text-xs sm:text-sm font-medium text-white shadow hover:burntred focus:outline-none focus:ring  sm:w-auto"
              >
                View Projects
              </a>

              <a
                href="#contact"
                className="block w-2/3 rounded bg-white px-3 sm:px-12 py-3 text-xs sm:text-sm font-medium text-burntred shadow hover:text-burntred/80 focus:outline-none focus:ring  sm:w-auto"
              >
                Become A Dealer
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
