import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import s1 from "../static/galleryphotos/slate-cypress/1.jpg";
import sr1 from "../static/galleryphotos/stoney-ridge/3.jpg";
import h1 from "../static/galleryphotos/hedgepeth/1.jpg";
import b1 from "../static/galleryphotos/bidking/4.jpg";

const TabbedGallery: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const tabs = [
    {
      label: "Carolina Barn Builders",
      images: [
        { src: s1, title: "Slate & Cypress", projectId: "slate-and-cypress" },
        { src: sr1, title: "Stoney Ridge", projectId: "stoney-ridge" },
      ],
    },
    {
      label: "Our Dealers",
      images: [
        { src: h1, title: "Hedgepeth", projectId: "hedgepeth" },
        { src: b1, title: "BidKing", projectId: "bidking" },
      ],
    },
  ];

  const handleImageClick = (projectId: string) => {
    navigate(`/project/${projectId}`);
  };

  const renderTabContent = (
    images: { src: string; title: string; projectId: string }[]
  ) => {
    return (
      <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-6xl">
        {images.map((image, index) => (
          <a href="#" key={index} className="relative w-full">
            <article className="relative overflow-hidden rounded-sm shadow transition hover:shadow-xl hover:scale-105">
              <img
                src={image.src}
                alt={image.title}
                className="absolute inset-0 h-full w-full object-cover"
              />
              <div className="relative bg-gradient-to-t from-black/70 to-black/20 pt-32 sm:pt-48 lg:pt-64">
                <div className="p-4 sm:p-6">
                  <h3 className="mt-0.5 text-xl font-semibold text-white">
                    {image.title}
                  </h3>
                  <button
                    className="inline-block mt-4 px-6 py-2 bg-burntred text-white text-sm rounded transition hover:bg-burntred/80"
                    onClick={() => handleImageClick(image.projectId)}
                  >
                    See More
                  </button>
                </div>
              </div>
            </article>
          </a>
        ))}
      </div>
    );
  };

  return (
    <div id="gallery" className="flex flex-col p-4 items-center">
      <p className="mb-2 font-semibold text-center  text-burntred md:mb-3 lg:text-lg">
        Get A Better Look At
      </p>
      <h1 className="text-5xl text-white text-center mb-6">
        Our Project’s From
      </h1>
      <div className="flex space-x-4 mb-6">
        {tabs.map((tab, index) => (
          <button
            key={tab.label}
            className={`block w-full max-w-xs rounded bg-burntred px-6 md:px-12 py-3 text-xs md:text-sm font-medium text-burntred shadow hover:burntred focus:outline-none ${
              activeTab === index
                ? "bg-white text-burntred"
                : "bg-burntred text-white"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
        {renderTabContent(tabs[activeTab].images)}
      </div>
    </div>
  );
};

export default TabbedGallery;
