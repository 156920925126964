import React from "react";
import Ticker from "framer-motion-ticker";

import c1 from "../static/clientslogos/1.png";
import c2 from "../static/clientslogos/2.png";
import c3 from "../static/clientslogos/3.png";
import c4 from "../static/clientslogos/4.png";
import c5 from "../static/clientslogos/5.png";

const ClientsTicker = () => {
  const clients = [
    {
      imgPath: c1,
      alt: "Client 1 Logo",
    },
    {
      imgPath: c2,
      alt: "Client 2 Logo",
    },
    {
      imgPath: c3,
      alt: "Client 3 Logo",
    },
    {
      imgPath: c4,
      alt: "Client 4 Logo",
    },
    {
      imgPath: c5,
      alt: "Client 5 Logo",
    },
    // Add more client logos as needed
  ];

  return (
    <div
      id="clients"
      className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-16  mx-auto overflow-hidden relative"
    >
      <div className="w-2/3 sm:w-1/2 lg:w-1/3 mx-auto text-center ">
        <h2 className="text-white text-2xl font-semibold">
          Our Trusted Dealers
        </h2>
      </div>
      <Ticker duration={20}>
        {clients.map((client, index) => (
          <div
            key={index}
            className="min-w-[150px] sm:min-w-[250px] flex-shrink-0 flex flex-col justify-center text-center p-6 sm:p-8 lg:p-16 transition-all"
            style={{
              margin: "5px",
              height: "150px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className="w-24 h-auto sm:w-36 lg:w-48 mx-auto grayscale"
              src={client.imgPath}
              alt={client.alt}
            />
          </div>
        ))}
      </Ticker>
    </div>
  );
};

export default ClientsTicker;
